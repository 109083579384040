@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.giuseppe {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}